/* You can add global styles to this file, and also import other style files */
body {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  font-family: "Barlow", sans-serif;
}