:root {
    --color-primary: #32C3E2;
    --color-primary-rgb: 50,195,226;
    --color-primary-contrast: #222222;
    --color-primary-contrast-rgb: 0,0,0;
    --color-primary-shade: #2cacc7;
    --color-primary-tint: #47c9e5;

    --color-secondary: #F59040;
    --color-secondary-rgb: 245,144,64;
    --color-secondary-contrast: #ffffff;
    --color-secondary-contrast-rgb: 255,255,255;
    --color-secondary-shade: #d87f38;
    --color-secondary-tint: #f69b53;

    --color-tertiary: #2297ca;
    --color-tertiary-rgb: 34,151,202;
    --color-tertiary-contrast: #ffffff;
    --color-tertiary-contrast-rgb: 255,255,255;
    --color-tertiary-shade: #1e85b2;
    --color-tertiary-tint: #38a1cf;

    --color-success: #2dd36f;
    --color-success-rgb: 45,211,111;
    --color-success-contrast: #ffffff;
    --color-success-contrast-rgb: 255,255,255;
    --color-success-shade: #28ba62;
    --color-success-tint: #42d77d;

    --color-warning: #ffc409;
    --color-warning-rgb: 255,196,9;
    --color-warning-contrast: #222222;
    --color-warning-contrast-rgb: 0,0,0;
    --color-warning-shade: #e0ac08;
    --color-warning-tint: #ffca22;

    --color-danger: #eb445a;
    --color-danger-rgb: 235,68,90;
    --color-danger-contrast: #ffffff;
    --color-danger-contrast-rgb: 255,255,255;
    --color-danger-shade: #cf3c4f;
    --color-danger-tint: #ed576b;

    --color-dark: #222222;
    --color-dark-rgb: 34,34,34;
    --color-dark-contrast: #ffffff;
    --color-dark-contrast-rgb: 255,255,255;
    --color-dark-shade: #1e1e1e;
    --color-dark-tint: #383838;

    --color-medium: #545454;
    --color-medium-rgb: 84,84,84;
    --color-medium-contrast: #ffffff;
    --color-medium-contrast-rgb: 255,255,255;
    --color-medium-shade: #4a4a4a;
    --color-medium-tint: #656565;

    --color-light: #ffffff;
    --color-light-rgb: 255,255,255;
    --color-light-contrast: #222222;
    --color-light-contrast-rgb: 0,0,0;
    --color-light-shade: #e0e0e0;
    --color-light-tint: #ffffff;

    --color-background: #f5f5f5;
    --color-background-rgb: 245,245,245;
    --color-background-contrast: #222222;
    --color-background-contrast-rgb: 0,0,0;
    --color-background-shade: #d8d8d8;
    --color-background-tint: #f6f6f6;

    --color-dark-background: #193160;
    --color-dark-background-rgb: 25,49,96;
    --color-dark-background-contrast: #ffffff;
    --color-dark-background-contrast-rgb: 255,255,255;
    --color-dark-background-shade: #162b54;
    --color-dark-background-tint: #304670;

    --color-light-primary: #bfdbe8;
    --color-light-primary-rgb: 191,219,232;
    --color-light-primary-contrast: #222222;
    --color-light-primary-contrast-rgb: 0,0,0;
    --color-light-primary-shade: #a8c1cc;
    --color-light-primary-tint: #c5dfea;

    --color-text-primary: #133e71;
    --color-text-primary-rgb: 19,62,113;
    --color-text-primary-contrast: #ffffff;
    --color-text-primary-contrast-rgb: 255,255,255;
    --color-text-primary-shade: #113763;
    --color-text-primary-tint: #2b517f;

    --color-dark-button: #07305b;
    --color-dark-button-rgb: 7,48,91;
    --color-dark-button-contrast: #ffffff;
    --color-dark-button-contrast-rgb: 255,255,255;
    --color-dark-button-shade: #062a50;
    --color-dark-button-tint: #20456b;

    --color-light-tertiary: #deecf3;
    --color-light-tertiary-rgb: 222,236,243;
    --color-light-tertiary-contrast: #222222;
    --color-light-tertiary-contrast-rgb: 0,0,0;
    --color-light-tertiary-shade: #c3d0d6;
    --color-light-tertiary-tint: #e1eef4;

    --space: 16px;

    --shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    --shadow-light: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}